<template>
  <div>
    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-cell-click="onCellClick"
      @on-sort-change="onSortChange"
      :pagination-options="{
        mode: 'pages',
        enabled: rows.length != 0,
        perPageDropdownEnabled: false,
        perPage: rows,
        setCurrentPage: search && serverParams.page,
        ofLabel: '/',
        pageLabel: 'Page',
        nextLabel: '',
        prevLabel: '',
      }"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      :line-numbers="true"
    >
      <div slot="emptystate">No Results.</div>
      <div slot="table-actions-bottom">View {{ this.totalFotter }}</div>
      <template slot="table-row" slot-scope="{ column, row }">
        <span
          v-if="column.field === 'fileNm'"
          v-html="common_getFile(row.fileNm, row.noticeNo)"
        />
        <span v-else-if="column.field === 'srvc'">
          {{ `${row.srvcNm}(${row.srvcId})` }}
        </span>
        <span
          v-else-if="column.field === 'fhirResrceBtn'"
          v-html="fhirResourceBtn(row.fhirResrce)"
        />
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import CommonMixins from '@/mixins/CommonMixins.js';

export default {
  components: { VueGoodTable },
  mixins: [CommonMixins],
  props: {
    columns: {
      type: Array,
    },
    fetchApi: {
      type: Function,
    },
    initLoad: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
    },
  },
  data() {
    return {
      totalRecords: 0,
      rows: [],
      serverParams: {
        page: this.page || 1,
        rows: 10,
        sidx: '',
        sord: 'asc',
      },
      localPagingData: {},
      search: false,
    };
  },
  created() {
    if (this.initLoad) {
      this.loadItems();
    }
  },
  computed: {
    totalFotter() {
      if (this.totalRecords === 0) {
        return '0 / 0';
      }
      const page = this.serverParams.page;
      const startRow = page === 1 ? page : (page - 1) * 10;
      const endRow =
        page * 10 > this.totalRecords ? this.totalRecords : page * 10;
      return `${startRow} - ${endRow} / ${this.totalRecords}`;
    },
  },
  methods: {
    // 데이터 가져오기
    async loadItems() {
      this.$dialogs.loading();

      const {
        data: { rows, totalRecords, pageId },
      } = await this.fetchApi(this.serverParams).catch(() => {
        this.$dialogs.close();
      });

      if (this.serverParams?.searchUrl?.indexOf('_count') > -1) {
        this.localPagingData = rows;
        this.rows = rows.slice(0, this.serverParams.page * 10);
      } else {
        this.localPagingData = {};
        this.rows = rows;
      }

      this.totalRecords = totalRecords;
      this.$dialogs.close();

      if (pageId) {
        this.serverParams.pageId = pageId;
      }
      this.search = false;
    },
    // 파라미터 세팅
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // 페이지 변경 시
    onPageChange({ currentPage }) {
      this.updateParams({ page: currentPage });

      if (this.localPagingData.length > 0) {
        this.rows = this.localPagingData.slice(
          (currentPage - 1) * 10,
          currentPage * 10,
        );
      } else {
        this.loadItems();
      }
    },
    // 정렬
    onSortChange(params) {
      this.updateParams({
        sord: params[0].type,
        sidx: params[0].field,
      });
      this.loadItems();
    },
    // 리스트 클릭 시
    onCellClick({ row, column }) {
      row.page = this.serverParams.page;
      if (column.field === 'fileNm') {
        return;
      } else if (column.field === 'fhirResrceBtn') {
        return this.$emit('resource', row);
      }
      this.$emit('detail', row);
    },
    // 검색
    onSearch(params) {
      this.search = true;
      this.updateParams(params);
      this.loadItems();
    },
    // 서비스 관리 > 회원관리 > 리소스 보기 버튼 생성
    fhirResourceBtn(data) {
      if (data) {
        return '<button type="submit" class="bt-sm bt-default" ><span>View Resource</span></button>';
      } else {
        return '<button type="submit" class="bt-sm bt-default" disabled><span>View Resource</span></button>';
      }
    },
  },
};
</script>
<style>
.vgt-inner-wrap {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
.vgt-responsive {
  border-top: 1px solid;
}
table.vgt-table {
  font-size: 14px;
  border: unset;
  border-bottom: 1px solid #ddd;
  table-layout: fixed;
}
.vgt-table thead {
  background: #f6f6f6;
}
.vgt-table thead > tr > th {
  background: #f6f6f6 !important;
  height: 51px;
}
.vgt-table thead > tr > th > span {
  font-weight: bold;
}
.vgt-wrap__footer {
  border: unset;
  background: unset;
  padding: unset;
}
.vgt-wrap__footer .footer__navigation {
  font-size: 14px;
  position: relative;
  text-align: center;
}

.vgt-pull-right {
  float: unset !important;
}

.vgt-table tbody tr:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}
.vgt-table tbody tr {
  background-color: white;
}
.vgt-table.bordered td:last-child,
.vgt-table.bordered th:last-child {
  border-right: unset !important;
}
.vgt-table.bordered td,
.vgt-table.bordered th {
  border: unset;
  border-right: 1px solid #ddd !important;
  text-align: center !important;
  vertical-align: middle !important;
  white-space: nowrap;
  overflow: hidden;
}
.vgt-table td {
  border-bottom: unset;
  border-right: 1px solid #ddd !important;
  height: 46px;
}
.vgt-table th.line-numbers {
  background: unset !important;
  width: auto;
}

/* paging */
.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid #555555;
}
.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid #555555;
}
.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid #555555;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron:after {
  transform: scale3d(1.5, 1.5, 1.5);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-top: -4px;
}

.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  width: 55px;
  height: 21px;
  border: 1px solid #ddd;
}

.footer__navigation__page-info {
  margin-top: 3px !important;
}

.vgt-wrap__footer .footer__navigation > button:nth-of-type(1) {
  position: absolute;
  left: 41%;
  padding: 1px;
}

.vgt-wrap__footer .footer__navigation > button:nth-of-type(2) {
  position: absolute;
  right: 41%;
  padding: 1px;
}

.vgt-wrap__footer
  .footer__navigation
  > button:nth-of-type(1):not(.disabled):hover {
  border: 1px solid #dcdcdc;
  background: rgb(243, 243, 243);
  padding: 0px;
  /* transition: transform 0.3s ease;
  transform: translateX(-3px); */
}

.vgt-wrap__footer
  .footer__navigation
  > button:nth-of-type(2):not(.disabled):hover {
  border: 1px solid #dcdcdc;
  background: rgb(243, 243, 243);
  padding: 0px;
  /* transition: transform 0.3s ease;
  transform: translateX(3px); */
}

/* table footer - totalRecords */
.vgt-wrap__actions-footer {
  border: unset;
  margin-top: 10px;
  text-align: right;
  color: #999999;
  padding-top: 4px;
}

.vgt-clearfix {
  position: relative;
  top: -22px;
}

/* sort arrow */
.vgt-table thead th.sorting-desc button:before {
  border-top: 5px solid #c02828;
}
.vgt-table thead th.sorting-asc button:after {
  border-bottom: 5px solid #c02828;
}
</style>
