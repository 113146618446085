var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"mode":"remote","pagination-options":{
      mode: 'pages',
      enabled: _vm.rows.length != 0,
      perPageDropdownEnabled: false,
      perPage: _vm.rows,
      setCurrentPage: _vm.search && _vm.serverParams.page,
      ofLabel: '/',
      pageLabel: 'Page',
      nextLabel: '',
      prevLabel: '',
    },"totalRows":_vm.totalRecords,"rows":_vm.rows,"columns":_vm.columns,"line-numbers":true},on:{"on-page-change":_vm.onPageChange,"on-cell-click":_vm.onCellClick,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
return [(column.field === 'fileNm')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.common_getFile(row.fileNm, row.noticeNo))}}):(column.field === 'srvc')?_c('span',[_vm._v(" "+_vm._s(((row.srvcNm) + "(" + (row.srvcId) + ")"))+" ")]):(column.field === 'fhirResrceBtn')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.fhirResourceBtn(row.fhirResrce))}}):_vm._e()]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No Results.")]),_c('div',{attrs:{"slot":"table-actions-bottom"},slot:"table-actions-bottom"},[_vm._v("View "+_vm._s(this.totalFotter))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }